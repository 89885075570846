import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import {
  Section,
  Inline,
  Badge,
  Accordion,
  Button,
  Icon,
} from "@intility/bifrost-react";
import { cn } from "~/utils/clsx";
import type { NewClusterFormSchema, NodePoolFormSchema } from "../../schemas";
import { formatNodeCount, formatCompute } from "../../utils/nodePools";
import styles from "./AddedNodePoolsSection.module.css";

export const AddedNodePoolsSection = ({
  formValues,
  removePool,
}: {
  formValues: NewClusterFormSchema;
  removePool?: (index: number) => void;
}) => {
  const addedPoolsCount = formValues.nodePools.length;

  return (
    <Section background="base-2" shadow={false} border>
      <Section.Header
        className={cn({
          [styles.noBottomBorder]: addedPoolsCount === 0,
        })}
      >
        <Inline align="center">
          Added node pools
          <Inline.Stretch />
          <Badge state="neutral">{formValues.nodePools.length} added</Badge>
        </Inline>
      </Section.Header>

      {addedPoolsCount > 0 && (
        <Section.Content className={styles.addedSectionContent}>
          <Accordion className="to-small">
            {formValues.nodePools.map((nodePool, index) => {
              const pool = { ...nodePool, name: `worker-pool-${index + 1}` };

              return (
                <AddedPoolAccordionItem
                  key={index}
                  nodePool={pool}
                  removePool={removePool ? () => removePool(index) : undefined}
                />
              );
            })}
          </Accordion>

          <table className={cn("from-small", styles.table)}>
            <thead>
              <tr>
                <th>Node pool name</th>
                <th>Node count</th>
                <th>Node compute</th>
                {removePool && <th></th>}
              </tr>
            </thead>
            <tbody>
              {formValues.nodePools.map((nodePool, index) => (
                <tr key={index}>
                  <td>{`worker-pool-${index + 1}`}</td>
                  <td>
                    {formatNodeCount({
                      minNodeCount: nodePool.minNodeCount ?? 0,
                      maxNodeCount: nodePool.maxNodeCount ?? 0,
                      nodeCount: nodePool.nodeCount ?? 0,
                      autoscalingEnabled: nodePool.autoscalingEnabled,
                    })}
                  </td>
                  <td>
                    {formatCompute(
                      nodePool.nodeCompute.cores,
                      nodePool.nodeCompute.memory,
                    )}
                  </td>
                  {removePool && (
                    <td>
                      <Button
                        small
                        variant="flat"
                        state="neutral"
                        onClick={() => removePool(index)}
                      >
                        <Icon icon={faTrash} />
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Section.Content>
      )}
    </Section>
  );
};

const AddedPoolAccordionItem = ({
  nodePool,
  removePool,
}: {
  nodePool: NodePoolFormSchema;
  removePool?: () => void;
}) => {
  const compute = nodePool.nodeCompute;

  return (
    <Accordion.Item
      title={nodePool.name}
      actions={
        removePool ? (
          <Accordion.Action
            icon={faTrash}
            onClick={() => removePool()}
            aria-label="Remove"
          />
        ) : undefined
      }
    >
      <dl className={styles.poolDetails}>
        <dt className={cn("bfc-base-2")}>Node count</dt>
        <dd>
          {formatNodeCount({
            minNodeCount: nodePool.minNodeCount ?? 0,
            maxNodeCount: nodePool.maxNodeCount ?? 0,
            nodeCount: nodePool.nodeCount ?? 0,
            autoscalingEnabled: nodePool.autoscalingEnabled,
          })}
        </dd>

        <dt className={cn("bfc-base-2")}>Node compute</dt>
        <dd>{formatCompute(compute.cores, compute.memory)}</dd>
      </dl>
    </Accordion.Item>
  );
};
