import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Tooltip } from "@intility/bifrost-react";
import { useContext } from "react";
import { AuthContext } from "~/context/AuthContext";
import { ModalContext } from "~/context/ModalContext";
import { useRole } from "~/hooks/useRole";
import type { UserDTO } from "../../api/getUsers";
import { UserAvatar } from "../Avatar";
import { UserTypeBadge } from "../UserTypeBadge";
import { EditOrgRoleModal } from "./EditOrgRoleModal";
import styles from "./UserProfile.module.css";

interface UserProfileProps {
  user: UserDTO;
}

export const UserProfile = ({ user }: UserProfileProps) => {
  const { handleModal } = useContext(ModalContext);
  const { userId } = useContext(AuthContext);

  const isOrgAdmin = useRole().isAdmin;
  const showEditButton = isOrgAdmin;
  const isViewingSelf = userId === user.id;

  return (
    <div className={styles.container}>
      <UserAvatar size={80} className="bf-h1 from-small" />

      <div className={styles.userInfoContainer}>
        <div>
          <h3 className="bf-h3">{user.name}</h3>
          <span className={styles.userUpn}>{user.upn}</span>
        </div>

        <div className={styles.roleContainer}>
          <UserTypeBadge roles={user.roles} />

          {showEditButton && (
            <>
              <hr className={styles.divider} />

              {isViewingSelf ? (
                <Tooltip
                  hideOnClick={false}
                  content={"You cannot change your own role"}
                >
                  <Button state="inactive" variant="flat" small>
                    <Icon icon={faPencil} marginRight />
                    Edit organization role
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  state="neutral"
                  variant="flat"
                  small
                  onClick={() =>
                    handleModal({
                      header: <>Edit organization role</>,
                      content: <EditOrgRoleModal user={user} />,
                    })
                  }
                >
                  <Icon icon={faPencil} marginRight />
                  Edit organization role
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
