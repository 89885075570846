import { FlagProvider, type IConfig } from "@unleash/proxy-client-react";
import type { PropsWithChildren } from "react";
import { env } from "~/utils/env";

// https://docs.getunleash.io/reference/sdks/react
const unleashConfig: IConfig = {
  appName: "Developer Platform Frontend",
  url: env.unleashApiUrl ?? "",
  clientKey: env.unleashApiKey ?? "INVALID_KEY",
};

export const UnleashProvider = ({ children }: PropsWithChildren) => {
  return <FlagProvider config={unleashConfig}>{children}</FlagProvider>;
};
