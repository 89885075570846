import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { ClusterRole, MemberType, QueryKey } from "~/constants";

export type NewClusterMember = {
  subject: {
    id: string;
    type: MemberType;
  };
  roles: ClusterRole[];
};

type AddClusterAccessDTO = {
  values: NewClusterMember[];
};

const addClusterAccess = (clusterId: string, dto: AddClusterAccessDTO) => {
  return api.post(`api/v1/clusters/${clusterId}/members`, {
    prefixUrl: API.IDP.url,
    json: dto,
  });
};

export const useAddClusterAccess = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      clusterId,
      dto,
    }: { clusterId: string; dto: AddClusterAccessDTO }) =>
      addClusterAccess(clusterId, dto),
    onSuccess: (data, variables) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [QueryKey.UserClusters] });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [QueryKey.TeamClusters] });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ClusterMembers, variables.clusterId],
      });
    },
  });
};
