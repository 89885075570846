export const ClusterRoleDescription = () => {
  return (
    <>
      Read more about the roles:{" "}
      <a
        href="https://article.intility.com/69963861-be3f-4588-8c3c-08dce9d0db05#:custom-roles-managed-by-intility"
        target="_blank"
        rel="noreferrer"
        className="bf-link"
      >
        reader
      </a>{" "}
      and{" "}
      <a
        href="https://article.intility.com/69963861-be3f-4588-8c3c-08dce9d0db05#:custom-roles-managed-by-intility"
        target="_blank"
        rel="noreferrer"
        className="bf-link"
      >
        admin
      </a>
    </>
  );
};
