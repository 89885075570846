import PageBanner from "~/components/PageBanner";
import styles from "./access.module.css";
import { AccessManagementTabs } from "../components/access/AccessManagementTabs";
import teamworkDark from "~/assets/teamwork-dark.svg";
import teamworkLight from "~/assets/teamwork-light.svg";
import { ThemeImage } from "~/components/ThemeImage";
import { cn } from "~/utils/clsx";
import { Outlet } from "react-router";

export const Access = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <PageBanner
          title="Teams"
          subTitle="Administrate team memberships to simplify access to resources"
          content={
            <ThemeImage
              className={cn("from-medium", styles.bannerIllustration)}
              srcLight={teamworkLight}
              srcDark={teamworkDark}
              height={120}
            />
          }
        />

        <AccessManagementTabs>
          <Outlet />
        </AccessManagementTabs>
      </div>
    </div>
  );
};
