import { Badge } from "@intility/bifrost-react";
import { ClusterRole } from "~/constants";
import { hasClusterAdminRole, hasClusterReaderRole } from "~/utils/roles";

export const ClusterRoleBadge = ({ roles }: { roles: ClusterRole[] }) => {
  const isAdmin = hasClusterAdminRole(roles);
  const isReader = hasClusterReaderRole(roles);

  switch (true) {
    case isAdmin:
      return (
        <Badge state="neutral" inverted>
          Admin
        </Badge>
      );

    case isReader:
      return <Badge state="neutral">Reader</Badge>;

    default:
      return null;
  }
};
