import { ThemeImage } from "~/components/ThemeImage";
import styles from "./callToAction.module.css";
import pekeDameDark from "~/assets/peke-dame.svg";
import pekeDameLight from "~/assets/peke-dame-light.svg";

const CallToAction = () => {
  return (
    <div className={styles.illustration}>
      <span className={styles.actionText}>
        Click the button to <br /> create your first cluster
      </span>

      <ThemeImage
        srcLight={pekeDameLight}
        srcDark={pekeDameDark}
        width={177}
        alt="Illustration of a woman pointing at create cluster button"
      />
    </div>
  );
};

export default CallToAction;
