import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, useFloatingMessage } from "@intility/bifrost-react";
import { useState } from "react";
import { type Control, type FormState, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { cn } from "~/utils/clsx";
import createRandomString from "~/utils/createRandomString";
import { useCreateCluster } from "../api/createCluster";
import { ClusterBreadcrumbs } from "../components/clusterDetails/ClusterBreadcrumbs";
import { BasicsSection } from "../components/newCluster/BasicsSection";
import { ClusterAccessAccordion } from "../components/newCluster/ClusterAccessAccordion";
import { ClusterSummary } from "../components/newCluster/ClusterSummary";
import CreateClusterFooter from "../components/newCluster/CreateClusterFooter";
import { NodePoolsSection } from "../components/newCluster/NodePoolsSection";
import { type ClusterStep, steps } from "../components/newCluster/steps";
import { type NewClusterFormSchema, newClusterSchema } from "../schemas";
import { mapFormPoolToNodePoolDTO } from "../utils/nodePools";
import styles from "./newCluster.module.css";

export const NewCluster = () => {
  const navigate = useNavigate();
  const createCluster = useCreateCluster();

  const [currentStep, setCurrentStep] = useState<ClusterStep>(steps[0]);

  const { handleSubmit, watch, control, reset, formState } = useForm({
    resolver: zodResolver(newClusterSchema),
    defaultValues: {
      clusterName: "",
      clusterNameSuffix: createRandomString(6),
      members: [],
      nodePools: [],
    },
  });

  const { showFloatingMessage } = useFloatingMessage();

  const onSubmit = handleSubmit(() => {});

  const handleCreateCluster = () => {
    if (createCluster.isPending) {
      return;
    }

    const data = formValues;

    const clusterName = data.clusterName + "-" + data.clusterNameSuffix;

    const membersToAssign = data.members.map((member) => ({
      subject: {
        id: member.subject.id,
        name: member.subject.name,
        type: member.subject.type,
      },
      roles: [member.role],
    }));

    const nodePools = data.nodePools.map((pool) =>
      mapFormPoolToNodePoolDTO(pool),
    );

    createCluster.mutate(
      {
        name: clusterName,
        nodePools,
        members: membersToAssign,
      },
      {
        onSuccess: (response) => {
          showFloatingMessage(
            <>
              The cluster <strong>{clusterName}</strong> has been successfully
              created.
            </>,
            {
              state: "success",
              noIcon: true,
            },
          );

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          navigate(`/clusters/${response.id}`);
        },
        onError: () => {
          showFloatingMessage(
            <>
              An error occurred while attempting to create the cluster{" "}
              <strong>{clusterName}</strong>. Please try again later.
            </>,
            {
              state: "warning",
              noIcon: true,
            },
          );

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          navigate("/clusters");
        },
      },
    );
  };

  const formValues = watch();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <ClusterBreadcrumbs cluster={undefined} />

        {currentStep.name === "form" ? (
          <FormStep
            control={control}
            formValues={formValues}
            formState={formState}
          />
        ) : (
          <SummaryStep formValues={formValues} />
        )}
      </div>

      <CreateClusterFooter
        steps={steps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        triggerValidation={() => void onSubmit()}
        cancelButtonOnClick={() => {
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          navigate("/clusters");
        }}
        createButtonOnClick={() => handleCreateCluster()}
        createButtonFetching={createCluster.isPending}
        isValid={formState.isValid}
      />
    </div>
  );
};

const FormStep = ({
  control,
  formValues,
  formState,
}: {
  control: Control<NewClusterFormSchema>;
  formValues: NewClusterFormSchema;
  formState: FormState<NewClusterFormSchema>;
}) => {
  return (
    <>
      <h3 className={cn(styles.header, "bf-h3")}>Create new cluster</h3>
      <span className="bfc-base-2">
        Give your cluster a name and define its node pools. You can also provide
        access to teams or other users.
      </span>

      <Grid gap={24} className={styles.sections}>
        <BasicsSection control={control} formValues={formValues} />
        <NodePoolsSection
          control={control}
          formValues={formValues}
          formErrors={formState.errors}
        />
        <ClusterAccessAccordion control={control} formValues={formValues} />
      </Grid>
    </>
  );
};

const SummaryStep = ({ formValues }: { formValues: NewClusterFormSchema }) => {
  return (
    <>
      <h3 className={cn(styles.header, "bf-h3")}>Summary</h3>
      <span className="bfc-base-2">Review the details of your new cluster</span>

      <div className={styles.summaryWrapper}>
        <ClusterSummary formValues={formValues} />
      </div>
    </>
  );
};
