import { z } from "zod";
import { ClusterRole, MemberType } from "~/constants";

const minNodeCountSchema = z.coerce
  .number({ invalid_type_error: "The value must be a number" })
  .min(2)
  .max(7);
const maxNodeCountSchema = z.coerce
  .number({ invalid_type_error: "The value must be a number" })
  .min(3)
  .max(8);
const nodeCountSchema = z.coerce
  .number({ invalid_type_error: "The value must be a number" })
  .min(2)
  .max(8);

const autoscaledPoolSchema = z.object({
  autoscalingEnabled: z.literal(true),
  minNodeCount: minNodeCountSchema,
  maxNodeCount: maxNodeCountSchema,
  nodeCount: nodeCountSchema.optional(),
});

const fixedPoolSchema = z.object({
  autoscalingEnabled: z.literal(false),
  minNodeCount: minNodeCountSchema.optional(),
  maxNodeCount: maxNodeCountSchema.optional(),
  nodeCount: nodeCountSchema,
});

const poolScalingSchema = z.discriminatedUnion("autoscalingEnabled", [
  autoscaledPoolSchema,
  fixedPoolSchema,
]);

export const nodePoolSchema = z
  .intersection(
    poolScalingSchema,
    z.object({
      name: z.string(),
      nodeCompute: z.object({
        label: z.string(),
        value: z.string(),
        cores: z.number(),
        memory: z.number(),
        badgeText: z.string().optional(),
      }),
    }),
  )
  .superRefine((data, ctx) => {
    if (data.autoscalingEnabled && data.minNodeCount && data.maxNodeCount) {
      if (data.maxNodeCount <= data.minNodeCount) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Must be greater than minimum node count",
          path: ["maxNodeCount"],
        });
      }
    }
  });

const nodePoolsSchema = z.object({
  nodePools: z.array(nodePoolSchema).min(1, {
    message: "At least one node pool is required",
  }),
});

export const newClusterSchema = z.object({
  clusterName: z
    .string()
    .min(2, { message: "Must be at least 2 characters long" })
    .max(23, { message: "Cannot be longer than 23 characters" })
    .regex(/^[a-z0-9][a-z0-9-]*[a-z0-9]$/, {
      message: "Your cluster name does not match the criteria",
    }),
  clusterNameSuffix: z.string(),
  members: z.array(
    z.object({
      subject: z.object({
        id: z.string(),
        name: z.string(),
        type: z.nativeEnum(MemberType),
      }),
      role: z.nativeEnum(ClusterRole),
    }),
  ),
  ...nodePoolsSchema.shape,
});

export type NewClusterFormSchema = z.infer<typeof newClusterSchema>;
export type NodePoolFormSchema = z.infer<typeof nodePoolSchema>;
