import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Input, Label } from "@intility/bifrost-react";
import { useSearch } from "~/hooks/useSearch";
import styles from "./UsersOptions.module.css";

export const UsersOptions = () => {
  const [search, setSearch] = useSearch();
  // const [displayMode, setDisplayMode] = useDisplayMode();

  return (
    <div className={styles.container}>
      <Label>Search</Label>

      <div className={styles.optionsContainer}>
        <Input
          className={styles.input}
          label="Search"
          hideLabel
          placeholder="Search users"
          defaultValue={search}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onChange={(e) => setSearch(e.target.value)}
          rightIcon
          icon={faSearch}
        />

        {/* <DisplayModeSelector
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
        /> */}
      </div>
    </div>
  );
};
