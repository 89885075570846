import { Inline, useFloatingMessage } from "@intility/bifrost-react";
import { useContext } from "react";
import { ModalContext } from "~/context/ModalContext";
import type { ClusterDto } from "~/types";
import { useEditNodePools } from "../../../api/editNodePools";
import type { NodePoolFormSchema } from "../../../schemas";
import { mapFormPoolToNodePoolDTO } from "../../../utils/nodePools";
import { NodePoolForm } from "../../newCluster/NodePoolForm";
import styles from "./AddNodePoolModal.module.css";

export const AddNodePoolModal = ({ cluster }: { cluster: ClusterDto }) => {
  const editNodePools = useEditNodePools();

  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const clusterId = cluster.id;

  const handleSubmit = (values: NodePoolFormSchema) => {
    const nodePoolDto = mapFormPoolToNodePoolDTO(values);

    const existingNodePools = cluster.nodePools;
    const updatedNodePools = [...existingNodePools, nodePoolDto];

    editNodePools.mutate(
      { clusterId, dto: { nodePools: updatedNodePools } },
      {
        onSuccess: () => {
          showFloatingMessage(
            <>
              Node pool successfully added. Please allow a few minutes for the
              nodes to be created.
            </>,
            {
              state: "success",
              noIcon: true,
            },
          );
        },
        onError: () => {
          showFloatingMessage(
            <>Failed to add node pool. Please try again later.</>,
            {
              state: "alert",
              noIcon: true,
            },
          );
        },
        onSettled: () => {
          handleModal({});
        },
      },
    );
  };

  return (
    <div className={styles.modal}>
      <NodePoolForm
        isModal
        onValidSubmit={(data) => handleSubmit(data)}
        nodePool={{
          name: "worker-pool-" + (cluster.nodePools.length + 1),
        }}
        footerContent={
          <>
            <NodePoolForm.CancelButton onClick={() => handleModal({})} />
            <Inline.Stretch />
            <NodePoolForm.AddPoolButton isLoading={editNodePools.isPending} />
          </>
        }
      />
    </div>
  );
};
