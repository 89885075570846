import { Breadcrumbs } from "@intility/bifrost-react";
import { Link, useLocation } from "react-router";
import type { ClusterDto } from "~/types";

interface ClusterBreadcrumbsProps {
  cluster: ClusterDto | undefined;
}

export const ClusterBreadcrumbs = ({ cluster }: ClusterBreadcrumbsProps) => {
  const { pathname } = useLocation();

  const clusterName = cluster?.name;
  const clusterId = cluster?.id;

  const breadcrumbs = [
    { route: "/clusters", label: "Clusters" },
    { route: "/clusters/new", label: "Create cluster" },
    { route: `/clusters/${clusterId}`, label: clusterName },
    { route: `/clusters/${clusterId}/access`, label: "Access" },
    { route: `/clusters/${clusterId}/nodepools`, label: "Node pools" },
  ].filter((breadcrumb) => pathname.includes(breadcrumb.route));

  const lastIndex = breadcrumbs.length - 1;

  return (
    <Breadcrumbs>
      <Breadcrumbs.Item>
        <Link to="/home">Home</Link>
      </Breadcrumbs.Item>

      {breadcrumbs.map(({ route, label }, index) => (
        <Breadcrumbs.Item key={route}>
          {index === lastIndex ? (
            <span>{label}</span>
          ) : (
            <Link to={route}>{label}</Link>
          )}
        </Breadcrumbs.Item>
      ))}
    </Breadcrumbs>
  );
};
