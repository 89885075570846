import { faExternalLink, faSearch } from "@fortawesome/pro-regular-svg-icons";
import {
  Button,
  Icon,
  Inline,
  Input,
  Section,
  useBreakpoint,
} from "@intility/bifrost-react";
import type { PaginationState } from "@tanstack/react-table";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useClusters } from "~/api/getClusters";
import tropicalChillDark from "~/assets/tropical-chill-dark.svg";
import tropicalChillLight from "~/assets/tropical-chill-light.svg";
import { ThemeImage } from "~/components/ThemeImage";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import { DataTable } from "~/components/table/DataTable";
import { useRole } from "~/hooks/useRole";
import { useSearch } from "~/hooks/useSearch";
import type { ClusterDto } from "~/types";
import CallToAction from "./CallToAction";
import { ClusterStatusCounts } from "./ClusterStatusCounts";
import { clusterColumns } from "./ClusterTableColumns";
import styles from "./clusterTable.module.css";

export const ClusterTable = () => {
  const clusters = useClusters({ refetchInterval: 10000 });
  const toMedium = useBreakpoint(null, "medium");
  const navigate = useNavigate();
  const role = useRole();

  const [search, setSearch] = useSearch();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 6,
  });

  if (clusters.isError) {
    return <UnavailableInfoError />;
  }

  if (clusters.isSuccess && clusters.data.length === 0) {
    return role.isAdmin ? <CallToAction /> : <NoClusters />;
  }

  const handleRowClick = (rowData: ClusterDto) => {
    const clusterId = rowData.id;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(`/clusters/${clusterId}`);
  };

  return (
    <Section radius className={styles.section}>
      <Section.Header className={styles.sectionHeader}>
        <Inline align="center">
          <Input
            className={styles.searchInput}
            small
            placeholder="Search clusters"
            label={undefined}
            icon={faSearch}
            clearable
            rightIcon
            value={search}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(e) => setSearch(e.target.value)}
          />
          <Inline.Stretch />
          <ClusterStatusCounts clusters={clusters.data ?? []} />
        </Inline>
      </Section.Header>

      <DataTable
        className={styles.table}
        columns={clusterColumns}
        data={clusters.data}
        onPaginationChange={setPagination}
        state={{
          globalFilter: search,
          pagination,
        }}
        initialState={{
          sorting: [{ id: "name", desc: false }],
        }}
        isLoading={clusters.isPending}
        noBorder
        limitExpandClick
        onRowClick={handleRowClick}
        ExpandedRowComponent={toMedium ? ExpandedClusterRow : undefined}
      />
    </Section>
  );
};

const ExpandedClusterRow = ({ rowData }: { rowData: ClusterDto }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "var(--bfs8)",
      }}
    >
      <span>Console URL</span>

      <a href={rowData.consoleUrl} target="_blank" rel="noreferrer">
        <Button small state="neutral">
          Connect to cluster
          <Icon icon={faExternalLink} marginLeft />
        </Button>
      </a>
    </div>
  );
};

const NoClusters = () => {
  return (
    <div className={styles.noClustersContainer}>
      <ThemeImage
        className={styles.noClustersIllustration}
        srcLight={tropicalChillLight}
        srcDark={tropicalChillDark}
        alt=""
      />
      <p className={styles.noClustersText}>
        There are no clusters to see here yet
      </p>
    </div>
  );
};
