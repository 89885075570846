import { useFieldArray, type Control, type FieldErrors } from "react-hook-form";
import { type NewClusterFormSchema } from "../../schemas";
import { Section, Icon, Grid, Inline, Badge } from "@intility/bifrost-react";
import {
  faCircleNodes,
  faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Divider } from "~/components/Divider";
import { AddedNodePoolsSection } from "./AddedNodePoolsSection";
import { AddNodePoolForm } from "./AddNodePoolForm";

interface NodePoolsSection {
  control: Control<NewClusterFormSchema>;
  formValues: NewClusterFormSchema;
  formErrors: FieldErrors<NewClusterFormSchema>;
}

export const NodePoolsSection = ({
  control,
  formValues,
  formErrors,
}: NodePoolsSection) => {
  const { append, remove, fields } = useFieldArray({
    control,
    name: "nodePools",
  });

  return (
    <Section shadow={false}>
      <Section.Header>
        <Inline align="center" gap={0}>
          <Icon icon={faCircleNodes} marginRight />
          Node pools
          <Inline.Stretch />
          {formErrors.nodePools && (
            <Badge state="alert" pill>
              <Icon icon={faExclamationCircle} marginRight />
              {formErrors.nodePools.message}
            </Badge>
          )}
        </Inline>
      </Section.Header>

      <Section.Content background="base-2" padding={24}>
        <Grid gap={24}>
          <AddNodePoolForm
            addedNodePoolsCount={fields.length}
            appendPool={append}
          />
          <Divider orientation="horizontal" />
          <AddedNodePoolsSection formValues={formValues} removePool={remove} />
        </Grid>
      </Section.Content>
    </Section>
  );
};
