import {
  Inline,
  Button,
  Grid,
  useFloatingMessage,
  Icon,
} from "@intility/bifrost-react";
import { MemberType } from "~/constants";
import { useContext } from "react";
import { ModalContext } from "~/context/ModalContext";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { useRemoveClusterAccess } from "../../../api/removeClusterAccess";

interface RemoveClusterAccessModalProps {
  cluster: {
    name: string;
    id: string;
    member: {
      id: string;
      name: string;
      memberType: MemberType;
    };
  };
}

export const RemoveClusterAccessModal = ({
  cluster,
}: RemoveClusterAccessModalProps) => {
  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const removeClusterAccess = useRemoveClusterAccess();

  const memberType = cluster.member.memberType;
  const memberName = cluster.member.name;

  const handleRemoveAccess = () => {
    removeClusterAccess.mutate(
      {
        clusterId: cluster.id,
        memberId: cluster.member.id,
        memberType: memberType,
      },
      {
        onError: () => {
          const userOrTeam = memberType === MemberType.User ? "user" : "team";

          showFloatingMessage(
            <>
              Failed to remove {userOrTeam} <strong>{memberName}</strong>&apos;s
              access to the cluster <strong>{cluster.name}</strong>. Please try
              again.
            </>,
            { noIcon: true, state: "alert" },
          );
        },
        onSuccess: () => {
          const userOrTeam = memberType === MemberType.User ? "User" : "Team";

          showFloatingMessage(
            <>
              {userOrTeam} <strong>{memberName}</strong>&apos;s access has been
              successfully removed from the cluster{" "}
              <strong>{cluster.name}</strong>.
            </>,
            { noIcon: true, state: "success" },
          );
        },
        onSettled: () => {
          handleModal({});
        },
      },
    );
  };

  return (
    <Grid gap={24}>
      <p>
        Are you sure you want to revoke {memberName}&apos;s access to cluster{" "}
        <strong>{cluster.name}</strong>?
      </p>

      <Inline>
        <Inline.Separator />

        <Button state="neutral" onClick={() => handleModal({})}>
          Cancel
        </Button>

        <Button
          state={removeClusterAccess.isPending ? "inactive" : "alert"}
          variant="filled"
          onClick={() => handleRemoveAccess()}
        >
          {removeClusterAccess.isPending && (
            <Icon icon={faSpinnerThird} spin marginRight />
          )}
          Remove {memberType === MemberType.User ? "user" : "team"}
        </Button>
      </Inline>
    </Grid>
  );
};
