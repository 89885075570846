import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import type { TeamDTO } from "./getTeam";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKey } from "~/constants";

export type CreateTeamDTO = {
  name: string;
  description: string;
};

const createTeam = (teamDto: CreateTeamDTO) => {
  return api
    .post("api/v1/teams", { json: teamDto, prefixUrl: API.IDP.url })
    .json<TeamDTO>();
};

export const useCreateTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (team: CreateTeamDTO) => createTeam(team),
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [QueryKey.Teams] });
    },
  });
};
