import { Icon } from "@intility/bifrost-react";
import type { ClusterDto } from "~/types";
import styles from "./clusterStatusCounts.module.css";
import { ClusterStatusIcon } from "~/constants";
import { getClusterStatusCounts } from "~/utils/clusterStatus";

export const ClusterStatusCounts = ({
  clusters,
}: { clusters: ClusterDto[] }) => {
  const clusterStatuses = getClusterStatusCounts(clusters);

  return (
    <div className={styles.container}>
      {clusterStatuses.deploying > 0 && (
        <span>
          <Icon
            icon={ClusterStatusIcon.deploying.icon}
            color={ClusterStatusIcon.deploying.color}
            className={styles.deploymentStateIcon}
            marginRight
            spin
          />
          {clusterStatuses.deploying} deploying
        </span>
      )}

      {clusterStatuses.ready > 0 && (
        <span>
          <Icon
            icon={ClusterStatusIcon.ready.icon}
            color={ClusterStatusIcon.ready.color}
            className={styles.deploymentStateIcon}
            marginRight
          />
          {clusterStatuses.ready} ready
        </span>
      )}

      {clusterStatuses.notReady > 0 && (
        <span>
          <Icon
            icon={ClusterStatusIcon.notReady.icon}
            color={ClusterStatusIcon.notReady.color}
            className={styles.deploymentStateIcon}
            marginRight
          />
          {clusterStatuses.notReady} not ready
        </span>
      )}
    </div>
  );
};
