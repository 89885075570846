import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Inline, Input, Section } from "@intility/bifrost-react";
import { type PropsWithChildren, useState } from "react";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import { Role } from "~/constants";
import { useRole } from "~/hooks/useRole";
import { useSearch } from "~/hooks/useSearch";
import type { TeamDTO } from "../../api/getTeam";
import { useTeamMembers } from "../../api/getTeamMembers";
import { AddTeamMembersModal } from "./AddTeamMembersModal";
import styles from "./TeamMembersSection.module.css";
import { TeamMembersTable } from "./TeamMembersTable";

interface TeamMembersSectionProps {
  team: TeamDTO;
}

export const TeamMembersSection = ({ team }: TeamMembersSectionProps) => {
  const teamMembers = useTeamMembers(team.id);

  const role = useRole();
  const isTeamOwner = team.roles.includes(Role.Owner);
  const isOrgAdmin = role.isAdmin;
  const shouldShowAddMemberButton =
    teamMembers.isSuccess && (isTeamOwner || isOrgAdmin);

  if (teamMembers.isError) {
    return (
      <Layout team={team} showButton={shouldShowAddMemberButton}>
        <UnavailableInfoError className={styles.errorContainer} />
      </Layout>
    );
  }

  return (
    <Layout team={team} showButton={shouldShowAddMemberButton}>
      <TeamMembersTable team={team} />
    </Layout>
  );
};

const Layout = ({
  children,
  team,
  showButton = true,
}: PropsWithChildren<{ team: TeamDTO; showButton?: boolean }>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useSearch();

  return (
    <Section className={styles.section}>
      <Section.Header className={styles.sectionHeader}>
        <Inline align="center">
          Members in this team
          <Inline.Stretch />
          <Input
            className={styles.memberSearchInput}
            small
            label="Search"
            hideLabel
            placeholder="Search members"
            rightIcon
            icon={faSearch}
            value={search}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(e) => setSearch(e.target.value)}
          />
          {showButton && (
            <Button
              className={styles.addToTeamButton}
              small
              variant="filled"
              state="default"
              onClick={() => setIsOpen(true)}
            >
              <Icon marginRight icon={faPlus} />
              Add member
            </Button>
          )}
        </Inline>
      </Section.Header>

      {children}

      <AddTeamMembersModal team={team} isOpen={isOpen} setIsOpen={setIsOpen} />
    </Section>
  );
};
