import { useContext } from "react";
import { AuthContext } from "~/context/AuthContext";

export const useRole = () => {
  const authContext = useContext(AuthContext);

  return {
    isAdmin: authContext.isAdmin,
    isMember: authContext.isMember,
  };
};
