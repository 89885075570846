import {
  faCopy,
  faExternalLink,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import {
  Button,
  Icon,
  Inline,
  Section,
  Tooltip,
  useFloatingMessage,
} from "@intility/bifrost-react";
import type { ClusterDto } from "~/types";
import styles from "./ClusterAdministrationCards.module.css";
import { useState } from "react";
import { getOcLoginCommand } from "../../utils/getLoginCommand";

interface ClusterAdministrationCardsProps {
  cluster: ClusterDto;
}

export const ClusterAdministrationCards = ({
  cluster,
}: ClusterAdministrationCardsProps) => {
  const ocLoginCommand = getOcLoginCommand(cluster);

  return (
    <>
      <Section className={styles.section}>
        <Section.Header>
          Console URL
          <Tooltip
            content="To connect to and access your cluster in your browser, click the
            button and login with SSO."
          >
            <Icon icon={faInfoCircle} className={styles.tooltipIcon} />
          </Tooltip>
        </Section.Header>

        <Section.Content className={styles.sectionContent}>
          <a
            href={cluster.consoleUrl}
            target="_blank"
            rel="noreferrer"
            className={styles.clusterConnectAnchor}
          >
            <Button state="neutral">
              Connect to cluster
              <Icon icon={faExternalLink} marginLeft />
            </Button>
          </a>
        </Section.Content>
      </Section>

      <Section className={styles.section}>
        <Section.Header>
          oc command
          <Tooltip
            content="To connect to and access your cluster using the OpenShift CLI, copy
            the oc command."
          >
            <Icon icon={faInfoCircle} className={styles.tooltipIcon} />
          </Tooltip>
        </Section.Header>
        <Section.Content className={styles.sectionContent}>
          <CopyButton buttonText={ocLoginCommand} textToCopy={ocLoginCommand} />
        </Section.Content>
      </Section>

      <Section className={styles.section}>
        <Section.Header>
          <Inline align="center" gap={0}>
            indev command
            <Tooltip
              content="To administrate your clusters using our CLI, copy the indev command.
            indev must be installed."
            >
              <Icon icon={faInfoCircle} className={styles.tooltipIcon} />
            </Tooltip>
            <Inline.Stretch />
            <a
              href="https://article.intility.com/51ec0d96-220b-4e66-402b-08dc346b24fd#get-started-with-indev:install-indev"
              target="_blank"
              rel="noreferrer"
            >
              <Button small variant="flat" state="neutral">
                <Icon icon={faExternalLink} marginRight />
                Install guide
              </Button>
            </a>
          </Inline>
        </Section.Header>
        <Section.Content className={styles.sectionContent}>
          <CopyButton buttonText="indev login" textToCopy="indev login" />
        </Section.Content>
      </Section>
    </>
  );
};

const CopyButton = ({
  buttonText,
  textToCopy,
}: { buttonText: string; textToCopy: string }) => {
  const { showFloatingMessage } = useFloatingMessage();

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(textToCopy).catch((e) => {
      showFloatingMessage("Failed to copy text", { state: "warning" });

      throw e;
    });

    setIsTooltipVisible(true);

    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 1000);
  };

  return (
    <Button
      state="neutral"
      className={styles.copyButton}
      onClick={() => void handleCopy()}
    >
      <span>{buttonText}</span>

      <Tooltip content="Copied!" placement="top" visible={isTooltipVisible}>
        <Icon icon={faCopy} marginLeft />
      </Tooltip>
    </Button>
  );
};
