import type { RowData } from "@tanstack/react-table";
import { DataTable } from "~/components/table/DataTable";
import { Role } from "~/constants";
import { useRole } from "~/hooks/useRole";
import { useSearch } from "~/hooks/useSearch";
import type { TeamDTO } from "../../api/getTeam";
import { useTeamMembers } from "../../api/getTeamMembers";
import styles from "./TeamMembersTable.module.css";
import { teamMemberColumns } from "./TeamMembersTableColumns";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    canEdit?: boolean;
    team?: {
      id: string;
      name: string;
      roles: Role[];
    };
  }
}

interface TeamMembersTableProps {
  team: TeamDTO;
}

export const TeamMembersTable = ({ team }: TeamMembersTableProps) => {
  const teamId = team.id;
  const teamMembers = useTeamMembers(teamId);
  const role = useRole();

  const [search] = useSearch();

  const isOrgAdmin = role.isAdmin;
  const isTeamOwner = team.roles.includes(Role.Owner);

  return (
    <DataTable
      className={styles.table}
      columns={teamMemberColumns}
      data={teamMembers.data}
      isLoading={teamMembers.isPending}
      noBorder
      noHeader
      state={{ globalFilter: search }}
      initialState={{
        sorting: [
          { id: "role", desc: true },
          { id: "name", desc: false },
        ],
      }}
      meta={{
        canEdit: isTeamOwner || isOrgAdmin,
        team: { id: teamId, name: team.name, roles: team.roles },
      }}
    />
  );
};
