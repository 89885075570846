import type { NodePoolDto } from "~/types";
import { NodePoolForm } from "./newCluster/NodePoolForm";
import { Inline } from "@intility/bifrost-react";
import { ModalContext } from "~/context/ModalContext";
import { useContext } from "react";
import type { NodePoolFormSchema } from "../schemas";
import styles from "./EditNodePoolModal.module.css";

export const EditNodePoolModal = ({
  nodePool,
  onValidSubmit,
  isLoading,
}: {
  nodePool: NodePoolDto;
  onValidSubmit: (data: NodePoolFormSchema) => void;
  isLoading: boolean;
}) => {
  const { handleModal } = useContext(ModalContext);

  return (
    <div className={styles.modal}>
      <NodePoolForm
        nodePool={nodePool}
        onValidSubmit={(data) => {
          onValidSubmit(data);
        }}
        isModal
        footerContent={
          <>
            <NodePoolForm.CancelButton onClick={() => handleModal({})} />
            <Inline.Stretch />
            <NodePoolForm.EditPoolButton isLoading={isLoading} />
          </>
        }
      />
    </div>
  );
};
