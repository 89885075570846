import type { RowData } from "@tanstack/react-table";
import { DataTable } from "~/components/table/DataTable";
import type { MemberType } from "~/constants";
import { useSearch } from "~/hooks/useSearch";
import { useUserTeams } from "../../api/getUserTeams";
import type { UserDTO } from "../../api/getUsers";
import { NoResults } from "../NoResults";
import styles from "./UserTeamsTable.module.css";
import { userTeamColumns } from "./UserTeamsTableColumns";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    member?: {
      id: string;
      name: string;
      memberType: MemberType;
    };
  }
}

export const UserTeamsTable = ({ user }: { user: UserDTO }) => {
  const userTeams = useUserTeams(user.id);

  const [search] = useSearch();

  if (userTeams.data?.length === 0 && userTeams.isSuccess) {
    return <NoResults message="This user is not a member of any teams" />;
  }

  return (
    <DataTable
      className={styles.table}
      columns={userTeamColumns}
      data={userTeams.data}
      isLoading={userTeams.isPending}
      noBorder
      noHeader
      state={{ globalFilter: search }}
      initialState={{
        sorting: [
          { id: "role", desc: true },
          { id: "name", desc: false },
        ],
      }}
      meta={{
        skeletonRows: 3,
        member: {
          id: user.id,
          name: user.name,
          memberType: "user",
        },
      }}
    />
  );
};
