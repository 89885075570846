import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Inline, Input, Section } from "@intility/bifrost-react";
import { type PropsWithChildren, useState } from "react";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import { ClusterRole } from "~/constants";
import { useRole } from "~/hooks/useRole";
import { useSearch } from "~/hooks/useSearch";
import type { ClusterDto } from "~/types";
import { useClusterMembers } from "../../../api/getClusterMembers";
import { AddClusterAccessModal } from "./AddClusterAccessModal";
import styles from "./ClusterMembersSection.module.css";
import { ClusterMembersTable } from "./ClusterMembersTable";

interface ClusterMembersSectionProps {
  cluster: ClusterDto;
}

export const ClusterMembersSection = ({
  cluster,
}: ClusterMembersSectionProps) => {
  const clusterMembers = useClusterMembers(cluster.id);
  const role = useRole();

  const isClusterAdmin = cluster.roles.includes(ClusterRole.Admin);
  const isOrgAdmin = role.isAdmin;
  const showGiveAccessButton = isClusterAdmin || isOrgAdmin;

  if (clusterMembers.isError) {
    return (
      <Layout cluster={cluster} showButton={showGiveAccessButton}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout cluster={cluster} showButton={showGiveAccessButton}>
      <ClusterMembersTable cluster={cluster} />
    </Layout>
  );
};

const Layout = ({
  children,
  cluster,
  showButton = true,
}: PropsWithChildren<{ cluster: ClusterDto; showButton: boolean }>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useSearch();

  return (
    <Section shadow={false}>
      <Section.Header className={styles.sectionHeader}>
        <Inline align="center">
          <Input
            className={styles.memberSearchInput}
            small
            label="Search"
            hideLabel
            placeholder="Search members"
            rightIcon
            icon={faSearch}
            value={search}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(e) => setSearch(e.target.value)}
          />

          <Inline.Stretch />

          {showButton && (
            <Button
              className={styles.giveAccessButton}
              small
              state="default"
              variant="filled"
              onClick={() => setIsOpen(true)}
            >
              <Icon icon={faPlus} marginRight />
              Give cluster access
            </Button>
          )}
        </Inline>
      </Section.Header>

      {children}

      <AddClusterAccessModal
        cluster={cluster}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Section>
  );
};
