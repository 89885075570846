import { DataTable } from "~/components/table/DataTable";
import type { ClusterDto } from "~/types";
import {
  useClusterMembers,
  type ClusterMemberDTO,
} from "../../../api/getClusterMembers";
import { clusterMemberColumns } from "./ClusterMembersTableColumns";
import styles from "./ClusterMembersTable.module.css";
import type { RowData } from "@tanstack/react-table";
import { type ClusterRole, MemberType } from "~/constants";
import { useBreakpoint } from "@intility/bifrost-react";
import { useSearch } from "~/hooks/useSearch";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    cluster?: {
      id: string;
      name: string;
      roles: ClusterRole[];
    };
  }
}

interface ClusterMembersTableProps {
  cluster: ClusterDto;
}

export const ClusterMembersTable = ({ cluster }: ClusterMembersTableProps) => {
  const clusterMembers = useClusterMembers(cluster.id);
  const [search] = useSearch();

  const toSmall = useBreakpoint(null, "small");

  return (
    <DataTable
      className={styles.table}
      columns={clusterMemberColumns}
      data={clusterMembers.data}
      isLoading={clusterMembers.isPending}
      state={{ globalFilter: search }}
      initialState={{
        sorting: [
          { id: "roles", desc: true },
          { id: "name", desc: false },
        ],
      }}
      noBorder
      ExpandedRowComponent={toSmall ? ExpandedRow : undefined}
      meta={{
        skeletonRows: 3,
        cluster: {
          id: cluster.id,
          name: cluster.name,
          roles: cluster.roles,
        },
      }}
    />
  );
};

const ExpandedRow = ({ rowData }: { rowData: ClusterMemberDTO }) => {
  const upnOrDescription =
    rowData.subject.type === MemberType.User ? "UPN" : "Team description";

  const userOrTeam = rowData.subject.type === MemberType.User ? "User" : "Team";

  return (
    <div className={styles.expandedRowContent}>
      <div>
        <div className={styles.expandedRowItemHeader}>Type</div>
        <div>{userOrTeam}</div>
      </div>

      <div>
        <div className={styles.expandedRowItemHeader}>{upnOrDescription}</div>
        <div>{rowData.subject.details}</div>
      </div>
    </div>
  );
};
