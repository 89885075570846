import { Section, Table } from "@intility/bifrost-react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { ClusterDto, NodePoolDto } from "~/types";
import { Link } from "react-router";
import { formatCompute } from "../../utils/nodePools";

const columnHelper = createColumnHelper<NodePoolDto>();

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
  }),
  columnHelper.display({
    header: "Node count",
    cell: (info) => {
      const nodePool = info.row.original;
      const hasAutoscaling = nodePool.autoscalingEnabled;

      if (hasAutoscaling) {
        return `${nodePool.minCount}–${nodePool.maxCount}`;
      }

      return nodePool.replicas;
    },
  }),
  columnHelper.display({
    header: "Node compute",
    cell: (info) => {
      const nodePool = info.row.original;

      const { cores, memory } = nodePool.compute;
      const parsedMemory = parseInt(memory);
      const computeLabel = formatCompute(cores, parsedMemory);

      return computeLabel;
    },
  }),
];

export const NodePoolsSection = ({ cluster }: { cluster: ClusterDto }) => {
  const nodePools = cluster.nodePools;

  const pagination = { pageSize: 5, pageIndex: 0 };

  const table = useReactTable({
    columns,
    data: nodePools,
    getCoreRowModel: getCoreRowModel(),
    state: { pagination },
    getPaginationRowModel: getPaginationRowModel(),
  });

  const displayMoreRow = nodePools.length > pagination.pageSize;

  return (
    <Section>
      <Link to="nodepools" className="bf-neutral-link">
        <Section.Header arrow>
          <span className="bf-neutral-link-text">Node pools</span>
        </Section.Header>
      </Link>

      <Section.Content>
        <Table noBorder>
          <Table.Header>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeaderCell key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>

          <Table.Body>
            {table.getRowModel().rows.map((row) => (
              <Table.Row key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}

            {displayMoreRow && (
              <Table.Row>
                <Table.Cell colSpan={999} align="center">
                  <span className="bfc-base-2">
                    ... and {nodePools.length - pagination.pageSize} more
                  </span>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
};
