import React from "react";
import { cn } from "~/utils/clsx";

type ThemeImage = {
  srcLight: string;
  srcDark: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const ThemeImage = ({
  srcLight,
  srcDark,
  alt,
  className,
  ...rest
}: ThemeImage) => {
  return (
    <>
      <img
        className={cn(className, "bf-light-only")}
        src={srcLight}
        alt={alt}
        {...rest}
      />

      <img
        className={cn(className, "bf-dark-only")}
        src={srcDark}
        alt={alt}
        {...rest}
      />
    </>
  );
};
