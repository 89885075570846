import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";
import type { ClusterDto, NodeSpecPreset } from "~/types";
import type { NewClusterMember } from "./addClusterAccess";

export type CreateNodePoolDto = {
  autoscalingEnabled: boolean;
  minCount?: number;
  maxCount?: number;
  replicas?: number;
  compute: {
    cores: number;
    memory: string;
  };
  preset?: NodeSpecPreset;
};

export type EditNodePoolDto = CreateNodePoolDto;

type CreateClusterDto = {
  name: string;
  nodePools: CreateNodePoolDto[];
  members: NewClusterMember[];
};

const createCluster = async (createDto: CreateClusterDto) => {
  return api
    .post("api/v1/clusters", {
      json: createDto,
      prefixUrl: API.IDP.url,
    })
    .json<ClusterDto>();
};

export const useCreateCluster = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (createDto: CreateClusterDto) => createCluster(createDto),
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [QueryKey.Clusters] });
    },
  });
};
