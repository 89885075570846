import { Badge } from "@intility/bifrost-react";
import styles from "./NodeComputeLabel.module.css";

export const NodeComputeLabel = ({
  label,
  badgeText,
}: { label: string; badgeText?: string }) => {
  return (
    <>
      {label}
      {badgeText && (
        <Badge state="attn" className={styles.computeSuggestionBadge}>
          {badgeText}
        </Badge>
      )}
    </>
  );
};
