import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Grid,
  Icon,
  Input,
  Label,
  Switch,
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { Controller, useForm } from "react-hook-form";
import { cn } from "~/utils/clsx";
import { type NodePoolFormSchema, nodePoolSchema } from "../../schemas";
import {
  defaultComputeOption,
  nodeComputeOptions,
} from "../../utils/nodePools";
import { NodeComputeLabel } from "../NodeComputeLabel";
import styles from "./AddNodePoolForm.module.css";

export const AddNodePoolForm = ({
  addedNodePoolsCount,
  appendPool,
}: {
  addedNodePoolsCount: number;
  appendPool: (data: NodePoolFormSchema) => void;
}) => {
  const { control, watch, handleSubmit } = useForm({
    resolver: zodResolver(nodePoolSchema),
    defaultValues: {
      name: "",
      autoscalingEnabled: false,
      nodeCount: 2,
      nodeCompute: defaultComputeOption,
      minNodeCount: 2,
      maxNodeCount: 3,
    },
  });

  const onSubmit = handleSubmit((data) => {
    appendPool(data);
  });

  const formValues = watch();

  return (
    <form onSubmit={(e) => void onSubmit(e)}>
      <Grid gap={24}>
        <Grid medium={2} gap={24}>
          <Controller
            control={control}
            name="nodeCompute"
            render={({ field, fieldState }) => (
              <Select
                {...field}
                className={cn(styles.computeSpecSelect, "bfl-inline-stretch")}
                label="Node compute"
                options={nodeComputeOptions}
                feedback={fieldState.error?.message}
                state={fieldState.error ? "alert" : undefined}
                formatOptionLabel={(option) => (
                  <NodeComputeLabel
                    label={option.label}
                    badgeText={option.badgeText}
                  />
                )}
              />
            )}
          />

          <div className={styles.nodePoolOptions}>
            <Controller
              control={control}
              name="autoscalingEnabled"
              render={({ field }) => (
                <div className={styles.autoscaling}>
                  <Label>Autoscaling</Label>
                  <div className={styles.switchWrapper}>
                    <Switch
                      {...field}
                      label=""
                      hideLabel
                      checked={field.value}
                      onChange={field.onChange}
                      value={undefined}
                    />
                  </div>
                </div>
              )}
            />

            <div className={styles.nodeCountWrapper}>
              {formValues.autoscalingEnabled ? (
                <div className={styles.autoscalingNodeCountInputs}>
                  <Controller
                    control={control}
                    name="minNodeCount"
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        className={cn(styles.nodeInput, "bfl-inline-stretch")}
                        label={
                          <>
                            <span className="to-small">Min node count</span>
                            <span className="from-small">
                              Minimum node count
                            </span>
                          </>
                        }
                        feedback={fieldState.error?.message}
                        state={fieldState.error ? "alert" : undefined}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="maxNodeCount"
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        className={cn(styles.nodeInput, "bfl-inline-stretch")}
                        label={
                          <>
                            <span className="to-small">Max node count</span>
                            <span className="from-small">
                              Maximum node count
                            </span>
                          </>
                        }
                        feedback={fieldState.error?.message}
                        state={fieldState.error ? "alert" : undefined}
                      />
                    )}
                  />
                </div>
              ) : (
                <Controller
                  control={control}
                  name="nodeCount"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      className={cn(styles.nodeInput, "bfl-inline-stretch")}
                      label="Node count"
                      feedback={fieldState.error?.message}
                      state={fieldState.error ? "alert" : undefined}
                    />
                  )}
                />
              )}
            </div>

            <div>
              <Label aria-hidden className={cn("from-small", styles.hidden)}>
                Add
              </Label>

              <Button
                type="submit"
                variant="filled"
                className={cn(styles.addButton)}
                disabled={addedNodePoolsCount >= 10}
              >
                <Icon icon={faPlus} marginRight />
                Add
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
