import { Icon, Section, Table } from "@intility/bifrost-react";
import { Link } from "react-router";
import type { ClusterDto } from "~/types";
import {
  useClusterMembers,
  type ClusterMemberDTO,
} from "../../api/getClusterMembers";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ClusterRoleBadge } from "~/components/ClusterRoleBadge";
import { MemberType } from "~/constants";
import type { PropsWithChildren } from "react";
import styles from "./AccessSection.module.css";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import { getClusterRoleFromRoles } from "~/utils/getRoleFromRoles";
import { sortByClusterRole } from "~/utils/sortByRole";

const columnHelper = createColumnHelper<ClusterMemberDTO>();

const columns = [
  columnHelper.accessor("subject.name", {
    id: "name",
    header: "Name",
  }),
  columnHelper.accessor("subject.type", {
    header: "Type",
    cell: function Cell(info) {
      const member = info.row.original;

      return member.subject.type === MemberType.Team ? "Team" : "User";
    },
  }),
  columnHelper.accessor("roles", {
    id: "roles",
    header: "Role",
    sortingFn: (rowA, rowB) => {
      const roleA = getClusterRoleFromRoles(rowA.original.roles);
      const roleB = getClusterRoleFromRoles(rowB.original.roles);

      return sortByClusterRole(roleA, roleB);
    },
    cell: function Cell(info) {
      const member = info.row.original;

      return <ClusterRoleBadge roles={member.roles} />;
    },
  }),
];

const fallbackData: never[] = [];

export const AccessSection = ({ cluster }: { cluster: ClusterDto }) => {
  const clusterMembers = useClusterMembers(cluster.id);

  const pagination = { pageSize: 5, pageIndex: 0 };

  const table = useReactTable({
    columns,
    data: clusterMembers.data ?? fallbackData,
    getCoreRowModel: getCoreRowModel(),
    state: { pagination },
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        { id: "roles", desc: true },
        { id: "name", desc: false },
      ],
    },
  });

  const memberCount = clusterMembers.data?.length ?? 0;
  const displayMoreRow = memberCount > pagination.pageSize;

  if (clusterMembers.isPending) {
    return (
      <Layout>
        <Section.Content className={styles.sectionContent}>
          <Icon.Spinner className="bfc-attn" size={40} />
        </Section.Content>
      </Layout>
    );
  }

  if (clusterMembers.isError) {
    return (
      <Layout>
        <Section.Content className={styles.sectionContent}>
          <UnavailableInfoError />
        </Section.Content>
      </Layout>
    );
  }

  return (
    <Layout>
      <Section.Content>
        <Table noBorder>
          <Table.Header>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeaderCell key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>

          <Table.Body>
            {table.getRowModel().rows.map((row) => (
              <Table.Row key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}

            {displayMoreRow && (
              <Table.Row>
                <Table.Cell colSpan={999} align="center">
                  <span className="bfc-base-2">
                    ... and {memberCount - pagination.pageSize} more
                  </span>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Section.Content>
    </Layout>
  );
};

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <Section className={styles.section}>
      <Link to="access" className="bf-neutral-link">
        <Section.Header arrow>
          <span className="bf-neutral-link-text">Cluster access</span>
        </Section.Header>
      </Link>

      {children}
    </Section>
  );
};
