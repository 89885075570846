import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Grid,
  Icon,
  Inline,
  Input,
  Label,
  Switch,
} from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import type { PropsWithChildren } from "react";
import { Controller, useForm } from "react-hook-form";
import type { NodePoolDto } from "~/types";
import { type NodePoolFormSchema, nodePoolSchema } from "../../schemas";
import {
  defaultComputeOption,
  formatCompute,
  nodeComputeOptions,
  suggestedCombinations,
} from "../../utils/nodePools";
import { NodeComputeLabel } from "../NodeComputeLabel";
import styles from "./NodePoolForm.module.css";

const getDefaultValues = (
  nodePool?: Partial<NodePoolDto>,
): NodePoolFormSchema => {
  const cores = nodePool?.compute?.cores;
  const memory = nodePool?.compute?.memory;
  const parsedMemory = memory ? Number.parseInt(memory) : undefined;

  const minNodeCount = nodePool?.minCount ?? 2;

  const nodeCompute =
    cores && parsedMemory
      ? {
          label: formatCompute(cores, parsedMemory),
          value: `${cores}-${parsedMemory}`,
          cores,
          memory: parsedMemory,
          badgeText: suggestedCombinations.find(
            (combination) =>
              combination.cpuCores === cores &&
              combination.memory === parsedMemory,
          )?.label,
        }
      : defaultComputeOption;

  if (nodePool?.autoscalingEnabled) {
    return {
      name: nodePool.name ?? "",
      autoscalingEnabled: true,
      minNodeCount,
      maxNodeCount: nodePool.maxCount ?? minNodeCount + 1,
      nodeCompute,
    };
  }

  return {
    name: nodePool?.name ?? "",
    autoscalingEnabled: false,
    nodeCount: nodePool?.replicas ?? 2,
    nodeCompute,
  };
};

const NodePoolForm = ({
  nodePool,
  onValidSubmit,
  footerContent,
  isModal = false,
}: {
  nodePool?: Partial<NodePoolDto>;
  onValidSubmit: (data: NodePoolFormSchema) => void;
  footerContent: React.ReactNode;
  isModal?: boolean;
}) => {
  const defaultValues = getDefaultValues(nodePool);

  const { handleSubmit, control, watch } = useForm<NodePoolFormSchema>({
    resolver: zodResolver(nodePoolSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = handleSubmit((data) => {
    onValidSubmit(data);
  });

  const formValues = watch();

  return (
    <form onSubmit={(e) => void onSubmit(e)}>
      <Grid gap={24}>
        <Grid medium={2}>
          <Input label="Node pool name" value={formValues.name} disabled />
        </Grid>

        <Controller
          control={control}
          name="autoscalingEnabled"
          render={({ field }) => (
            <div className={styles.autoscaling}>
              <Label>Enable autoscaling</Label>
              <Switch
                {...field}
                label=""
                hideLabel
                checked={field.value}
                onChange={field.onChange}
                value={undefined}
              />
            </div>
          )}
        />

        <Grid medium={2} className={styles.nodeCount}>
          {formValues.autoscalingEnabled ? (
            <>
              <Controller
                control={control}
                name="minNodeCount"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    label="Minimum node count"
                    feedback={fieldState.error?.message}
                    state={
                      fieldState.isTouched && fieldState.error
                        ? "alert"
                        : undefined
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="maxNodeCount"
                defaultValue={3}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    label="Maximum node count"
                    feedback={fieldState.error?.message}
                    state={
                      fieldState.isTouched && fieldState.error
                        ? "alert"
                        : undefined
                    }
                  />
                )}
              />
            </>
          ) : (
            <Controller
              control={control}
              name="nodeCount"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  label="Node count"
                  feedback={fieldState.error?.message}
                  state={
                    fieldState.isTouched && fieldState.error
                      ? "alert"
                      : undefined
                  }
                />
              )}
            />
          )}
        </Grid>

        <Controller
          control={control}
          name="nodeCompute"
          render={({ field, fieldState }) => (
            <Select
              {...field}
              className={styles.nodeCompute}
              label="Node compute"
              options={nodeComputeOptions}
              formatOptionLabel={(option) => (
                <NodeComputeLabel
                  label={option.label}
                  badgeText={option.badgeText}
                />
              )}
              menuPortalTarget={
                isModal ? undefined : document.querySelector("body")
              }
              state={
                fieldState.isTouched && fieldState.error ? "alert" : undefined
              }
            />
          )}
        />

        <FormFooter className={styles.footer}>{footerContent}</FormFooter>
      </Grid>
    </form>
  );
};

const FormFooter = ({
  className,
  children,
}: PropsWithChildren<{ className: string }>) => {
  return <Inline className={className}>{children}</Inline>;
};

const AddPoolButton = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Button type="submit" state="default" variant="filled" disabled={isLoading}>
      <Icon icon={faPlus} marginRight />
      Add node pool
    </Button>
  );
};

const EditPoolButton = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Button type="submit" state="default" variant="filled" disabled={isLoading}>
      <Icon icon={faCheck} marginRight />
      Confirm changes
    </Button>
  );
};

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button state="neutral" variant="flat" onClick={onClick}>
      Cancel
    </Button>
  );
};

NodePoolForm.AddPoolButton = AddPoolButton;
NodePoolForm.EditPoolButton = EditPoolButton;
NodePoolForm.CancelButton = CancelButton;
NodePoolForm.Footer = FormFooter;

export { NodePoolForm };
