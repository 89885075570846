import { Grid, Section } from "@intility/bifrost-react";
import { AddedNodePoolsSection } from "./AddedNodePoolsSection";
import type { NewClusterFormSchema } from "../../schemas";
import { AddedClusterMembersSection } from "./AddedClusterMembersSection";
import styles from "./ClusterSummary.module.css";

export const ClusterSummary = ({
  formValues,
}: {
  formValues: NewClusterFormSchema;
}) => {
  return (
    <Grid gap={24}>
      <Section background="base-2" shadow={false} border>
        <Section.Header>Full cluster name</Section.Header>
        <Section.Content>
          <div className={styles.clusterNameBox}>
            <span className="bf-medium">
              {formValues.clusterName + "-" + formValues.clusterNameSuffix}
            </span>
          </div>
        </Section.Content>
      </Section>
      <AddedNodePoolsSection formValues={formValues} />
      <AddedClusterMembersSection formValues={formValues} />
    </Grid>
  );
};
