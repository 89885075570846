import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { Box, Button, Grid, Icon } from "@intility/bifrost-react";
import { useContext } from "react";
import { ClusterRole, ClusterStatus, ClusterStatusIcon } from "~/constants";
import { ModalContext } from "~/context/ModalContext";
import type { ClusterDto } from "~/types";
import { cn } from "~/utils/clsx";
import { getClusterStatus } from "~/utils/clusterStatus";
import { DeleteModal } from "../clusterList/DeleteModal";
import styles from "./ClusterInformation.module.css";
import { useRole } from "~/hooks/useRole";

interface ClusterInformationProps {
  cluster: ClusterDto;
}

export const ClusterInformation = ({ cluster }: ClusterInformationProps) => {
  const clusterName = cluster.name;
  const clusterStatus = getClusterStatus(cluster);

  const isClusterAdmin = cluster.roles.includes(ClusterRole.Admin);
  const isOrgAdmin = useRole().isAdmin;
  const showAdminActions = isClusterAdmin || isOrgAdmin;

  return (
    <Box
      padding
      shadow
      radius
      background="base-3"
      className={styles.cardContainer}
    >
      <Grid small={2}>
        <div>
          <div className={styles.clusterStatus}>
            <Icon
              icon={ClusterStatusIcon[clusterStatus].icon}
              color={ClusterStatusIcon[clusterStatus].color}
              spin={clusterStatus === ClusterStatus.deploying}
              marginRight
            />

            <span>
              {clusterStatus === ClusterStatus.deploying && "In deployment"}
              {clusterStatus === ClusterStatus.ready && "Ready"}
              {clusterStatus === ClusterStatus.notReady && "Not ready"}
            </span>
          </div>

          <h3 className={cn("bf-h3", styles.clusterName)}>{clusterName}</h3>
        </div>

        <div className={styles.clusterActionsButtonContainer}>
          {showAdminActions && <DeleteClusterButton cluster={cluster} />}
        </div>
      </Grid>
    </Box>
  );
};

const DeleteClusterButton = ({ cluster }: { cluster: ClusterDto }) => {
  const { handleModal } = useContext(ModalContext);

  return (
    <Button
      state="alert"
      variant="flat"
      onClick={() =>
        handleModal({
          header: <>Delete cluster</>,
          content: <DeleteModal cluster={cluster} />,
        })
      }
    >
      <Icon icon={faTrash} marginRight />
      Delete cluster
    </Button>
  );
};
