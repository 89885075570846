import { Button, Inline, useFloatingMessage } from "@intility/bifrost-react";
import type { NodePoolDto } from "~/types";
import styles from "./DeleteNodePoolModal.module.css";
import { ModalContext } from "~/context/ModalContext";
import { useContext } from "react";
import { useEditNodePools } from "~/features/clusters/api/editNodePools";

export const DeleteNodePoolModal = ({
  clusterId,
  nodePools,
  index,
}: { clusterId: string; nodePools: NodePoolDto[]; index: number }) => {
  const editNodePools = useEditNodePools();

  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const nodePool = nodePools.at(index);

  if (!nodePool) return null;

  const handleDeletePool = () => {
    const updatedNodePools = nodePools.filter((_, i) => i !== index);

    editNodePools.mutate(
      { clusterId, dto: { nodePools: updatedNodePools } },
      {
        onSuccess: () => {
          showFloatingMessage(
            <>
              Node pool successfully deleted. Please allow a few minutes for the
              nodes to be terminated.
            </>,
            { noIcon: true, state: "success" },
          );
        },
        onError: () => {
          showFloatingMessage(
            <>Failed to delete node pool. Please try again later.</>,
            { noIcon: true, state: "alert" },
          );
        },
        onSettled: () => {
          handleModal({});
        },
      },
    );
  };

  return (
    <div>
      <p>Are you sure you want to delete {nodePool.name}?</p>

      <Inline className={styles.footer}>
        <Button state="neutral" onClick={() => handleModal({})}>
          Cancel
        </Button>

        <Inline.Stretch />

        <Button
          variant="filled"
          state="alert"
          onClick={() => handleDeletePool()}
          disabled={editNodePools.isPending}
        >
          Delete node pool
        </Button>
      </Inline>
    </div>
  );
};
