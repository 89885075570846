import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import {
  Section,
  Inline,
  Badge,
  Accordion,
  Button,
  Icon,
  Grid,
} from "@intility/bifrost-react";
import { ClusterRoleBadge } from "~/components/ClusterRoleBadge";
import { cn } from "~/utils/clsx";
import type { NewClusterFormSchema } from "../../schemas";
import styles from "./AddedClusterMembersSection.module.css";

export const AddedClusterMembersSection = ({
  formValues,
  removeMember,
}: {
  formValues: NewClusterFormSchema;
  removeMember?: (index: number) => void;
}) => {
  const addedMembersCount = formValues.members.length;

  return (
    <Section background="base-2" shadow={false} border>
      <Section.Header
        className={cn({
          [styles.noBottomBorder]: addedMembersCount === 0,
        })}
      >
        <Inline align="center">
          Added members
          <Inline.Stretch />
          <Badge state="neutral">{formValues.members.length} added</Badge>
        </Inline>
      </Section.Header>

      {addedMembersCount > 0 && (
        <Section.Content>
          <Accordion className="to-small">
            {formValues.members.map((member, index) => (
              <AddedMemberAccordionItem
                key={index}
                member={member}
                removeMember={
                  removeMember ? () => removeMember(index) : undefined
                }
              />
            ))}
          </Accordion>

          <table className={cn("from-small", styles.table)}>
            <thead>
              <tr>
                <th>Member</th>
                <th>Role</th>
                {removeMember && <th></th>}
              </tr>
            </thead>
            <tbody>
              {formValues.members.map((member, index) => (
                <tr key={index}>
                  <td>{member.subject.name}</td>
                  <td>
                    <ClusterRoleBadge roles={[member.role]} />
                  </td>
                  {removeMember && (
                    <td>
                      <Button
                        small
                        variant="flat"
                        state="neutral"
                        onClick={() => removeMember(index)}
                      >
                        <Icon icon={faTrash} />
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Section.Content>
      )}
    </Section>
  );
};

const AddedMemberAccordionItem = ({
  member,
  removeMember,
}: {
  member: NewClusterFormSchema["members"][number];
  removeMember?: () => void;
}) => {
  return (
    <Accordion.Item
      className={styles.addedAccordionItem}
      title={member.subject.name}
      actions={
        removeMember ? (
          <Accordion.Action
            icon={faTrash}
            onClick={() => removeMember()}
            aria-label="Remove"
          />
        ) : undefined
      }
    >
      <Grid gap={24}>
        <dl>
          <dt className={cn("bfc-base-2")}>Role</dt>
          <dd>
            <ClusterRoleBadge roles={[member.role]} />
          </dd>
        </dl>
      </Grid>
    </Accordion.Item>
  );
};
