import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { Grid, Icon, Input, Section } from "@intility/bifrost-react";
import { type Control, Controller } from "react-hook-form";
import type { NewClusterFormSchema } from "../../schemas";

interface BasicsSectionProps {
  control: Control<NewClusterFormSchema>;
  formValues: NewClusterFormSchema;
}

export const BasicsSection = ({ control, formValues }: BasicsSectionProps) => {
  return (
    <Section shadow={false}>
      <Section.Header>
        <Icon icon={faEdit} marginRight />
        Basics
      </Section.Header>

      <Section.Content background="base-2" padding={24}>
        <Grid medium={2}>
          <Controller
            control={control}
            name="clusterName"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                label="Name"
                description="A cluster name is 2-23 lowercase, alphanumeric characters (a-z, 0-9 and -)"
                placeholder="my-cluster"
                required
                maxLength={23} // the input is suffixed with 7 characters, so we set the max length to 23
                minLength={1}
                feedback={fieldState.error?.message}
                state={fieldState.error ? "alert" : undefined}
              />
            )}
          />

          <Input
            label="Full cluster name"
            description="A random string is appended to the cluster name to ensure uniqueness"
            placeholder="Unique cluster name"
            disabled
            value={
              formValues.clusterName &&
              formValues.clusterName + "-" + formValues.clusterNameSuffix
            }
          />
        </Grid>
      </Section.Content>
    </Section>
  );
};
