import {
  faEllipsis,
  faPencil,
  faPlus,
  faSearch,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Button,
  Dropdown,
  Icon,
  Inline,
  Input,
  Section,
  useFloatingMessage,
} from "@intility/bifrost-react";
import { type RowData, createColumnHelper } from "@tanstack/react-table";
import { useContext, useState } from "react";
import { DataTable } from "~/components/table/DataTable";
import { ModalContext } from "~/context/ModalContext";
import { useEditNodePools } from "~/features/clusters/api/editNodePools";
import type { NodePoolFormSchema } from "~/features/clusters/schemas";
import {
  formatCompute,
  mapFormPoolToNodePoolDTO,
} from "~/features/clusters/utils/nodePools";
import { useIsClusterAdmin } from "~/hooks/useIsClusterAdmin";
import { useIsOrgAdmin } from "~/hooks/useIsOrgAdmin";
import { useSearch } from "~/hooks/useSearch";
import type { ClusterDto, NodePoolDto } from "~/types";
import { EditNodePoolModal } from "../../EditNodePoolModal";
import { AddNodePoolModal } from "./AddNodePoolModal";
import styles from "./ClusterNodePoolsSection.module.css";
import { DeleteNodePoolModal } from "./DeleteNodePoolModal";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    clusterId?: string;
    nodePools?: NodePoolDto[];
    canModify?: boolean;
  }
}

const columnHelper = createColumnHelper<NodePoolDto>();

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
  }),
  columnHelper.display({
    header: "Node count",
    cell: (info) => {
      const nodePool = info.row.original;
      const hasAutoscaling = nodePool.autoscalingEnabled;

      if (hasAutoscaling) {
        return `${nodePool.minCount}–${nodePool.maxCount}`;
      }

      return nodePool.replicas;
    },
  }),
  columnHelper.display({
    header: "Node compute",
    cell: (info) => {
      const nodePool = info.row.original;

      const { cores, memory } = nodePool.compute;
      const parsedMemory = Number.parseInt(memory);

      return formatCompute(cores, parsedMemory);
    },
  }),
  columnHelper.display({
    id: "actions",
    cell: function Cell(info) {
      const [dropdownOpen, setDropdownOpen] = useState(false);

      const editNodePools = useEditNodePools();

      const { handleModal } = useContext(ModalContext);
      const { showFloatingMessage } = useFloatingMessage();

      const canModify = info.table.options.meta?.canModify;

      if (!canModify) {
        return null;
      }

      const clusterId = info.table.options.meta?.clusterId;
      const nodePools = info.table.options.meta?.nodePools;
      const nodePool = info.row.original;
      const rowIndex = info.row.index;

      if (!nodePools || !clusterId) return null;

      const handleEditNodePool = (data: NodePoolFormSchema) => {
        if (editNodePools.isPending) return;

        const updatedNodePools = [...nodePools];

        const updatedNodePool = {
          ...nodePool,
          ...mapFormPoolToNodePoolDTO(data),
        };

        updatedNodePools[rowIndex] = updatedNodePool;

        editNodePools.mutate(
          {
            clusterId,
            dto: { nodePools: updatedNodePools },
          },
          {
            onSuccess: () => {
              showFloatingMessage(
                <>
                  Node pool successfully edited. Please allow a few minutes for
                  the changes to take effect.
                </>,
                { noIcon: true, state: "success" },
              );
            },
            onError: () => {
              showFloatingMessage(
                <>Failed to edit node pool. Please try again later.</>,
                { noIcon: true, state: "alert" },
              );
            },
            onSettled: () => {
              handleModal({});
            },
          },
        );
      };

      return (
        <Dropdown
          placement="bottom"
          visible={dropdownOpen}
          onClickOutside={() => setDropdownOpen(false)}
          content={
            <div className={styles.dropdownContent}>
              <Button
                state="neutral"
                variant="flat"
                small
                onClick={() => {
                  handleModal({
                    header: <>Edit node pool</>,
                    content: (
                      <EditNodePoolModal
                        nodePool={nodePool}
                        onValidSubmit={(data) => handleEditNodePool(data)}
                        isLoading={editNodePools.isPending}
                      />
                    ),
                  });

                  setDropdownOpen(false);
                }}
              >
                <Icon icon={faPencil} fixedWidth marginRight />
                Edit node pool
              </Button>

              <Button
                state="neutral"
                variant="flat"
                small
                onClick={() => {
                  if (nodePools.length <= 1) {
                    showFloatingMessage(
                      <>
                        A cluster must have at least one node pool. Please add a
                        new node pool before deleting this one.
                      </>,
                      { noIcon: true, state: "warning" },
                    );

                    return;
                  }

                  handleModal({
                    header: <>Delete node pool</>,
                    content: (
                      <DeleteNodePoolModal
                        clusterId={clusterId}
                        nodePools={nodePools}
                        index={rowIndex}
                      />
                    ),
                  });

                  setDropdownOpen(false);
                }}
              >
                <Icon icon={faTrashCan} fixedWidth marginRight />
                Delete node pool
              </Button>
            </div>
          }
        >
          <Button
            state="neutral"
            variant="flat"
            small
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <Icon icon={faEllipsis} fixedWidth />
          </Button>
        </Dropdown>
      );
    },
  }),
];

interface ClusterNodePoolsSectionProps {
  cluster: ClusterDto;
}

export const ClusterNodePoolsSection = ({
  cluster,
}: ClusterNodePoolsSectionProps) => {
  const { handleModal } = useContext(ModalContext);

  const [search, setSearch] = useSearch();

  const isClusterAdmin = useIsClusterAdmin(cluster.roles);
  const isOrgAdmin = useIsOrgAdmin();

  const canModifyPools = isClusterAdmin || isOrgAdmin;

  return (
    <Section shadow={false}>
      <Section.Header className={styles.sectionHeader}>
        <Inline align="center">
          <Input
            className={styles.searchInput}
            small
            label="Search"
            hideLabel
            placeholder="Search node pools"
            rightIcon
            icon={faSearch}
            value={search}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(e) => setSearch(e.target.value)}
          />

          <Inline.Stretch />

          {canModifyPools && (
            <Button
              className={styles.addNodePoolButton}
              variant="filled"
              small
              state="default"
              onClick={() =>
                handleModal({
                  header: <>Add node pool</>,
                  content: <AddNodePoolModal cluster={cluster} />,
                })
              }
            >
              <Icon icon={faPlus} marginRight />
              Add node pool
            </Button>
          )}
        </Inline>
      </Section.Header>

      <DataTable
        className={styles.table}
        columns={columns}
        data={cluster.nodePools}
        state={{ globalFilter: search }}
        meta={{
          clusterId: cluster.id,
          nodePools: cluster.nodePools,
          canModify: canModifyPools,
        }}
        noBorder
      />
    </Section>
  );
};
