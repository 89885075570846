import { cn } from "~/utils/clsx";
import styles from "./Divider.module.css";

export const Divider = ({
  orientation,
}: {
  orientation: "horizontal" | "vertical";
}) => {
  return (
    <div
      className={cn({
        [styles.horizontal]: orientation === "horizontal",
        [styles.vertical]: orientation === "vertical",
      })}
    />
  );
};
