import type { NodePoolDto } from "~/types";
import type { CreateNodePoolDto } from "../api/createCluster";
import type { NodePoolFormSchema } from "../schemas";

export const formatCompute = (cpu = 0, memory = 0) => {
  return `${cpu} vCPU, ${memory} GB RAM`;
};

export const formatNodeCount = ({
  autoscalingEnabled,
  nodeCount,
  minNodeCount,
  maxNodeCount,
}: {
  autoscalingEnabled: boolean;
  nodeCount: number;
  minNodeCount: number;
  maxNodeCount: number;
}) => {
  if (autoscalingEnabled) {
    return `${minNodeCount}–${maxNodeCount}`;
  }

  return `${nodeCount}`;
};

const presets = [
  {
    cpuCores: 2,
    memoryOptions: [8, 12, 16, 24, 32],
  },
  {
    cpuCores: 4,
    memoryOptions: [8, 12, 16, 24, 32, 48],
  },
  {
    cpuCores: 6,
    memoryOptions: [16, 24, 32, 48, 64],
  },
  {
    cpuCores: 8,
    memoryOptions: [16, 24, 32, 48, 64, 88],
  },
  {
    cpuCores: 12,
    memoryOptions: [48, 64, 88, 128],
  },
  {
    cpuCores: 16,
    memoryOptions: [64, 88, 128, 192],
  },
] as const;

export const suggestedCombinations = [
  {
    cpuCores: 2,
    memory: 8,
    label: "Small",
  },
  {
    cpuCores: 4,
    memory: 16,
    label: "Medium",
  },
  {
    cpuCores: 6,
    memory: 24,
    label: "Large",
  },
] as const;

const suggestedComputeOptions = {
  label: "Suggested",
  options: suggestedCombinations.map((combination) => ({
    label: formatCompute(combination.cpuCores, combination.memory),
    value: `${combination.cpuCores}-${combination.memory}` as const,
    cores: combination.cpuCores,
    memory: combination.memory,
    badgeText: combination.label,
  })),
};

const nodeComputeOptions = [
  suggestedComputeOptions,
  ...presets.map((preset) => ({
    label: `${preset.cpuCores} vCPU`,
    cores: preset.cpuCores,
    options: preset.memoryOptions.map((memory) => ({
      label: formatCompute(preset.cpuCores, memory),
      value: `${preset.cpuCores}-${memory}` as const,
      cores: preset.cpuCores,
      memory: memory,
    })),
  })),
];

export const defaultComputeOption = suggestedComputeOptions.options[0];

const mapFormPoolToNodePoolDTO = (
  pool: NodePoolFormSchema,
): CreateNodePoolDto => {
  const autoscalingEnabled = pool.autoscalingEnabled;

  const compute: NodePoolDto["compute"] = {
    cores: pool.nodeCompute.cores,
    memory: `${pool.nodeCompute.memory}Gi`,
  };

  if (autoscalingEnabled) {
    return {
      autoscalingEnabled,
      minCount: pool.minNodeCount,
      maxCount: pool.maxNodeCount,
      compute,
      replicas: undefined,
    };
  }

  return {
    autoscalingEnabled,
    replicas: pool.nodeCount,
    compute,
    minCount: undefined,
    maxCount: undefined,
  };
};

const removeInvalidNodePoolProperties = (nodePool: {
  autoscalingEnabled: boolean;
  minCount?: number;
  maxCount?: number;
  replicas?: number;
}) => {
  if (nodePool.autoscalingEnabled) {
    return {
      ...nodePool,
      replicas: undefined,
    };
  }

  return {
    ...nodePool,
    minCount: undefined,
    maxCount: undefined,
  };
};

export {
  nodeComputeOptions,
  mapFormPoolToNodePoolDTO,
  removeInvalidNodePoolProperties,
};
