import { Button, Icon, Inline } from "@intility/bifrost-react";
import styles from "./createClusterFooter.module.css";
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons";
import type { ButtonState } from "@intility/bifrost-react/Button";
import type { ClusterSteps, ClusterStep } from "./steps";

const CreateClusterFooter = ({
  steps,
  currentStep,
  setCurrentStep,
  triggerValidation,
  cancelButtonOnClick,
  createButtonOnClick,
  createButtonFetching,
  isValid,
}: {
  steps: ClusterSteps;
  currentStep: ClusterStep;
  setCurrentStep: (step: ClusterStep) => void;
  triggerValidation: () => void;
  cancelButtonOnClick: () => void;
  createButtonOnClick: () => void;
  createButtonFetching: boolean;
  isValid: boolean;
}) => {
  const isLastStep = currentStep.id === steps[steps.length - 1].id;
  const previousStep = steps.find((step) => step.id === currentStep.id - 1);
  const nextStep = steps.find((step) => step.id === currentStep.id + 1);

  return (
    <div className={styles.footer}>
      <Inline className={styles.footerContent} align="center">
        <Button
          state="neutral"
          variant="flat"
          small
          onClick={() => cancelButtonOnClick()}
        >
          Cancel
        </Button>

        <Inline.Stretch />

        {isLastStep ? (
          <Inline>
            <Button
              className={styles.stepButton}
              state="neutral"
              small
              onClick={() => {
                if (previousStep) {
                  setCurrentStep(previousStep);
                }
              }}
            >
              <Icon className="from-small" icon={faArrowLeft} marginRight />
              Back
            </Button>

            <CreateButton
              state={isValid ? "default" : "inactive"}
              onClick={createButtonOnClick}
              isFetching={createButtonFetching}
            />
          </Inline>
        ) : (
          <Inline align="center">
            <Button
              className={styles.stepButton}
              state={isValid ? "neutral" : "inactive"}
              small
              onClick={() => {
                if (!isValid) {
                  void triggerValidation();
                  return;
                }

                if (nextStep) {
                  setCurrentStep(nextStep);
                }
              }}
            >
              Next
              <Icon className="from-small" icon={faArrowRight} marginLeft />
            </Button>
          </Inline>
        )}
      </Inline>
    </div>
  );
};

const CreateButton = ({
  onClick,
  isFetching,
  state,
}: {
  onClick: () => void;
  isFetching: boolean;
  state: ButtonState;
}) => {
  return (
    <Button
      className={styles.stepButton}
      variant={"filled"}
      state={state}
      onClick={onClick}
      small
    >
      {isFetching ? (
        <Icon.Spinner size={14} />
      ) : (
        <>
          Create
          <Icon icon={faCheck} className="from-small" marginLeft />
        </>
      )}
    </Button>
  );
};

export default CreateClusterFooter;
