import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";
import { removeInvalidNodePoolProperties } from "../utils/nodePools";
import type { EditNodePoolDto } from "./createCluster";

type EditNodePoolsDTO = {
  nodePools: Array<EditNodePoolDto>;
};

const editNodePools = (clusterId: string, dto: EditNodePoolsDTO) => {
  const correctedNodePools = dto.nodePools.map((pool) =>
    removeInvalidNodePoolProperties(pool),
  );

  return api.put(`api/v1/clusters/${clusterId}/nodepools`, {
    json: { nodePools: correctedNodePools },
    prefixUrl: API.IDP.url,
  });
};

export const useEditNodePools = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      clusterId,
      dto,
    }: { clusterId: string; dto: EditNodePoolsDTO }) =>
      editNodePools(clusterId, dto),
    onSuccess: (response, variables) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Cluster, variables.clusterId],
      });
    },
  });
};
