import { useFieldArray, type Control } from "react-hook-form";
import type { NewClusterFormSchema } from "../../schemas";
import { Accordion, Grid } from "@intility/bifrost-react";
import { faKey } from "@fortawesome/pro-regular-svg-icons";
import styles from "./ClusterAccessAccordion.module.css";
import { Divider } from "~/components/Divider";
import { AddClusterAccessForm } from "./AddClusterMemberForm";
import { AddedClusterMembersSection } from "./AddedClusterMembersSection";
import { cn } from "~/utils/clsx";

interface ClusterAccessAccordionProps {
  control: Control<NewClusterFormSchema>;
  formValues: NewClusterFormSchema;
}

export const ClusterAccessAccordion = ({
  control,
  formValues,
}: ClusterAccessAccordionProps) => {
  const { append, remove } = useFieldArray({
    control,
    name: "members",
  });

  return (
    <Accordion className={styles.accordion}>
      <Accordion.Item
        className={styles.accordionItem}
        defaultActive
        noPadding
        icon={faKey}
        title={
          <>
            <span className="bf-medium bf-strong">Cluster access</span>{" "}
            <span className={cn(styles.italic, "bfc-base-2")}>(optional)</span>
          </>
        }
      >
        <Grid className={styles.accordionContent} gap={24}>
          <AddClusterAccessForm
            clusterFormValues={formValues}
            addMember={append}
          />
          <Divider orientation="horizontal" />
          <AddedClusterMembersSection
            formValues={formValues}
            removeMember={remove}
          />
        </Grid>
      </Accordion.Item>
    </Accordion>
  );
};
