import {
  Button,
  Grid,
  Inline,
  Input,
  useFloatingMessage,
} from "@intility/bifrost-react";
import type { UserDTO } from "../../api/getUsers";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Role } from "~/constants";
import { useEditOrganizationRole } from "../../api/editOrganizationRole";
import { ModalContext } from "~/context/ModalContext";
import { useContext } from "react";
import { getRoleFromRoles } from "~/utils/getRoleFromRoles";
import Select from "@intility/bifrost-react-select";
import styles from "./EditOrgRoleModal.module.css";
import { OrganizationRoleDescriptionTable } from "../userDetails/OrganizationRoleDescriptionTable";

const editRoleSchema = z.object({
  userId: z.string(),
  roles: z.object(
    {
      label: z.string(),
      value: z.nativeEnum(Role),
    },
    { required_error: "Required" },
  ),
});

type EditRoleSchema = z.infer<typeof editRoleSchema>;

const roleOptions: Array<{ label: string; value: Role }> = [
  { label: "Admin", value: Role.Owner },
  { label: "Member", value: Role.Member },
];

interface EditOrgRoleModalProps {
  user: UserDTO;
}

export const EditOrgRoleModal = ({ user }: EditOrgRoleModalProps) => {
  const editOrgRole = useEditOrganizationRole();

  const { showFloatingMessage } = useFloatingMessage();
  const { handleModal } = useContext(ModalContext);

  const existingUserRole = getRoleFromRoles(user.roles);

  const { control, handleSubmit, formState } = useForm<EditRoleSchema>({
    resolver: zodResolver(editRoleSchema),
    defaultValues: {
      userId: user.id,
    },
  });

  const onSubmit = handleSubmit((data) => {
    if (editOrgRole.isPending) {
      return;
    }

    editOrgRole.mutate(
      {
        userId: data.userId,
        dto: { roles: [data.roles.value] },
      },
      {
        onSuccess: () => {
          showFloatingMessage(
            <>{user.name}&apos;s role has been successfully updated.</>,
            {
              state: "success",
              noIcon: true,
            },
          );
        },
        onError: () => {
          showFloatingMessage(
            <>
              There was an error updating {user.name}&apos;s role. Please try
              again.
            </>,
            {
              state: "alert",
              noIcon: true,
            },
          );
        },
        onSettled: () => {
          handleModal({});
        },
      },
    );
  });

  return (
    <form onSubmit={(e) => void onSubmit(e)} className={styles.modal}>
      <Grid gap={24}>
        <Input label="User" value={user.name} disabled />

        <div>
          <Controller
            name="roles"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                label="Role"
                placeholder="Select a role"
                className={styles.roleSelect}
                options={roleOptions.map((option) => ({
                  ...option,
                  isDisabled: option.value === existingUserRole,
                }))}
                feedback={fieldState.error?.message}
                state={
                  fieldState.isTouched && fieldState.error ? "alert" : undefined
                }
              />
            )}
          />

          <OrganizationRoleDescriptionTable />
        </div>

        <Inline>
          <Inline.Stretch />

          <Button state="neutral" onClick={() => handleModal({})}>
            Cancel
          </Button>

          <Button
            type="submit"
            variant="filled"
            state={
              !formState.isValid || editOrgRole.isPending
                ? "inactive"
                : "default"
            }
          >
            Confirm
          </Button>
        </Inline>
      </Grid>
    </form>
  );
};
