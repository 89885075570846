import { Icon } from "@intility/bifrost-react";
import { HTTPError } from "ky";
import type { PropsWithChildren } from "react";
import { useParams } from "react-router";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import { ErrorPage } from "~/pages/error/ErrorPage";
import type { ClusterDto as TCluster } from "~/types";
import { useCluster } from "../api/getCluster";
import { ClusterBreadcrumbs } from "../components/clusterDetails/ClusterBreadcrumbs";
import { ClusterNodePoolsSection } from "../components/clusterDetails/nodepools/ClusterNodePoolsSection";
import styles from "./ClusterNodePools.module.css";

export const ClusterNodePools = () => {
  const { clusterId } = useParams();

  const cluster = useCluster(clusterId ?? "");

  if (cluster.isPending) {
    return (
      <Layout cluster={cluster.data}>
        <Icon.Spinner size={42} className={styles.loadingSpinner} />
      </Layout>
    );
  }

  if (
    cluster.error instanceof HTTPError &&
    cluster.error.response.status === 403
  ) {
    return <ErrorPage code={403} />;
  }

  if (cluster.isError) {
    return (
      <Layout cluster={cluster.data}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout cluster={cluster.data}>
      <ClusterNodePoolsSection cluster={cluster.data} />
    </Layout>
  );
};

const Layout = ({
  children,
  cluster,
}: PropsWithChildren<{
  cluster: TCluster | undefined;
}>) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <ClusterBreadcrumbs cluster={cluster} />

        <h3 className="bf-h3">Node pools</h3>

        {children}
      </div>
    </div>
  );
};
