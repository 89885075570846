import { createContext, type PropsWithChildren } from "react";
import { useMe } from "~/api/getMe";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import { Role } from "~/constants";
import styles from "./AuthContext.module.css";
import { Button } from "@intility/bifrost-react";

interface AuthorizationContextProps {
  isAdmin: boolean;
  isMember: boolean;
  userId: string;
  organizationId: string;
}

export const AuthContext = createContext<AuthorizationContextProps>({
  isAdmin: false,
  isMember: false,
  userId: "",
  organizationId: "",
});

const AuthProvider = ({ children }: PropsWithChildren) => {
  const me = useMe();

  if (me.isPending) {
    return null;
  }

  if (me.isError) {
    return (
      <div className={styles.errorWrapper}>
        <UnavailableInfoError
          className={styles.errorContent}
          errorMessage={
            "We were unable to determine your access level. Please refresh the page or contact support for assistance."
          }
          additionalInfo={
            <div className={styles.additionalInfoContainer}>
              <Button state="neutral" onClick={() => window.location.reload()}>
                Refresh page
              </Button>

              <a
                href="https://my.intility.com/chat"
                target="_blank"
                rel="noreferrer"
              >
                <Button state="neutral">Chat with us</Button>
              </a>
            </div>
          }
        />
      </div>
    );
  }

  const organizationRoles = me.data.organizationRoles;
  const isAdmin = organizationRoles.includes(Role.Owner);
  const isMember = organizationRoles.includes(Role.Member);
  const userId = me.data.id;
  const organizationId = me.data.organizationId;

  return (
    <AuthContext.Provider
      value={{
        isAdmin,
        isMember,
        userId,
        organizationId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
