import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Inline, Input, Section } from "@intility/bifrost-react";
import { type PropsWithChildren, useContext } from "react";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import { ModalContext } from "~/context/ModalContext";
import { useSearch } from "~/hooks/useSearch";
import { useUserTeams } from "../../api/getUserTeams";
import type { UserDTO } from "../../api/getUsers";
import { AddUserToTeamsModal } from "./AddUserToTeamsModal";
import styles from "./UserTeamsSection.module.css";
import { UserTeamsTable } from "./UserTeamsTable";

interface UserTeamsSectionProps {
  user: UserDTO;
}

export const UserTeamsSection = ({ user }: UserTeamsSectionProps) => {
  const userTeams = useUserTeams(user.id);

  if (userTeams.isError) {
    return (
      <Layout user={user} showButton={userTeams.isSuccess}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout user={user} showButton={userTeams.isSuccess}>
      <UserTeamsTable user={user} />
    </Layout>
  );
};

const Layout = ({
  children,
  user,
  showButton = true,
}: PropsWithChildren<{ user: UserDTO; showButton?: boolean }>) => {
  const { handleModal } = useContext(ModalContext);

  const [search, setSearch] = useSearch();

  return (
    <Section className={styles.section}>
      <Section.Header className={styles.sectionHeader}>
        <Inline align="center">
          Team memberships
          <Inline.Stretch />
          <Input
            className={styles.searchInput}
            small
            label="Search"
            hideLabel
            placeholder="Search team memberships"
            rightIcon
            icon={faSearch}
            value={search}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(e) => setSearch(e.target.value)}
          />
          {showButton && (
            <Button
              className={styles.addUserButton}
              small
              variant="filled"
              state="default"
              onClick={() =>
                handleModal({
                  header: <>Add user to team</>,
                  content: <AddUserToTeamsModal user={user} />,
                })
              }
            >
              <Icon marginRight icon={faPlus} />
              Add to team
            </Button>
          )}
        </Inline>
      </Section.Header>

      {children}
    </Section>
  );
};
