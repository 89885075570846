import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, Label, Button, Icon } from "@intility/bifrost-react";
import Select from "@intility/bifrost-react-select";
import { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { ClusterRole, MemberType } from "~/constants";
import { AuthContext } from "~/context/AuthContext";
import { useTeams } from "~/features/access/api/getTeams";
import { useUsers } from "~/features/access/api/getUsers";
import { cn } from "~/utils/clsx";
import type { NewClusterFormSchema } from "../../schemas";
import {
  type ClusterAssignableOption,
  clusterRoleOptions,
} from "../clusterDetails/access/AddClusterAccessModal";
import { ClusterRoleDescription } from "../ClusterRoleDescription";
import { z } from "zod";
import styles from "./AddClusterMemberForm.module.css";

const accessSchema = z.object({
  role: z.object({
    label: z.string().min(1, { message: "Select a role" }),
    value: z.nativeEnum(ClusterRole),
  }),
  members: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
        memberType: z.nativeEnum(MemberType),
      }),
    )
    .min(1, { message: "Select at least one member" }),
});

export const AddClusterAccessForm = ({
  clusterFormValues,
  addMember,
}: {
  clusterFormValues: NewClusterFormSchema;
  addMember: (data: NewClusterFormSchema["members"][number]) => void;
}) => {
  const teams = useTeams();
  const users = useUsers();

  const { userId: myUserId } = useContext(AuthContext);

  const { control, handleSubmit, formState, reset } = useForm({
    resolver: zodResolver(accessSchema),
    defaultValues: {
      role: {
        label: "",
        value: undefined,
      },
      members: [],
    },
  });

  const onSubmit = handleSubmit((data) => {
    const membersToAdd = data.members.map((member) => ({
      role: data.role.value,
      subject: {
        id: member.value,
        name: member.label,
        type: member.memberType,
      },
    }));

    for (const member of membersToAdd) {
      addMember(member);
    }

    reset();
  });

  const getMemberOptions = () => {
    const sortedTeams =
      teams.data?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

    const sortedUsers =
      users.data?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

    const existingMemberIds = new Set<string>();

    for (const member of clusterFormValues.members) {
      existingMemberIds.add(member.subject.id);
    }

    const teamOptions: ClusterAssignableOption[] = [];
    const userOptions: ClusterAssignableOption[] = [];

    for (const team of sortedTeams) {
      if (!existingMemberIds.has(team.id)) {
        teamOptions.push({
          label: team.name,
          value: team.id,
          memberType: MemberType.Team,
        });
      }
    }

    for (const user of sortedUsers) {
      if (!existingMemberIds.has(user.id) && user.id !== myUserId) {
        userOptions.push({
          label: user.name,
          value: user.id,
          memberType: MemberType.User,
        });
      }
    }

    return [
      { label: "Teams", options: teamOptions },
      { label: "Users", options: userOptions },
    ];
  };

  const memberOptions = getMemberOptions();

  return (
    <form onSubmit={(e) => void onSubmit(e)}>
      <Grid gap={24}>
        <Grid gap={24} medium={2}>
          <Controller
            control={control}
            name="role"
            render={({ field, fieldState }) => (
              <Select
                {...field}
                label="Role"
                placeholder="Select role"
                description={<ClusterRoleDescription />}
                options={clusterRoleOptions}
                value={field.value.label ? field.value : null}
                feedback={formState.errors.role?.label?.message}
                state={fieldState.error ? "alert" : undefined}
              />
            )}
          />

          <div className={styles.columnTwo}>
            <Controller
              control={control}
              name="members"
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  className="bfl-inline-stretch"
                  label="Member(s)"
                  placeholder="Select one or more"
                  description="A member can be a team or a user"
                  options={memberOptions}
                  isLoading={teams.isPending || users.isPending}
                  isMulti
                  blurInputOnSelect={false}
                  feedback={fieldState.error?.message}
                  menuPortalTarget={document.querySelector("body")}
                  state={fieldState.error ? "alert" : undefined}
                />
              )}
            />

            <div>
              <Label aria-hidden className={cn("from-small", styles.hidden)}>
                Add
              </Label>

              <div aria-hidden className={cn("from-small", styles.hidden)}>
                Description
              </div>

              <Button
                type="submit"
                variant="filled"
                className={styles.addButton}
              >
                <Icon icon={faPlus} marginRight />
                Add
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
