import { captureException } from "@sentry/react";
import React from "react";
import { useRouteError } from "react-router";
import { ErrorPage } from "./ErrorPage";

const ErrorBoundary = () => {
  const error = useRouteError() as Error;

  React.useEffect(() => {
    captureException(error);
  }, [error]);

  return <ErrorPage />
}

export { ErrorBoundary }
