import { ClusterStatus } from "~/constants";
import type { ClusterDto } from "~/types";

export const getClusterStatus = (cluster: ClusterDto) => {
  const status = cluster.status;

  if (status.deployment.active) {
    return ClusterStatus.deploying;
  } else if (status.ready.status) {
    return ClusterStatus.ready;
  } else {
    return ClusterStatus.notReady;
  }
};

export const getClusterStatusCounts = (clusters: ClusterDto[]) => {
  const clusterStatuses = {
    [ClusterStatus.deploying]: 0,
    [ClusterStatus.ready]: 0,
    [ClusterStatus.notReady]: 0,
  };

  for (const cluster of clusters) {
    const clusterStatus = getClusterStatus(cluster);

    clusterStatuses[clusterStatus]++;
  }

  return clusterStatuses;
};
